import { mobile, styled } from '@obvio/app'
import { Tab, Tabs as UiTabs } from '@obvio/ui'
import { slugify } from '@obvio/utils'

import { CenteredSection } from '../CenteredSection'
import { Columns, Rows } from '@/components/Renderer'

import type { Column, Row } from '../utils'
import type { Either } from '@obvio/utils'
import type { ReactElement, ReactNode } from 'react'

type Tab = {
  label?: string
  name?: ReactNode
} & Either<{ rows: Row[] }, { columns: Column[] }>

type TabsProps = {
  tabs: Tab[]
}

export const TabsMaxWidth = styled(UiTabs)`
  width: 100%;
  .rc-tabs-content-holder {
    padding-top: ${(theme) => theme.spacing.large};
  }
  .rc-tabs-nav {
    margin-bottom: 0;
    padding-bottom: 0;
    position: sticky;
    top: 0rem;
    min-width: 100%;
    padding-top: ${(theme) => theme.spacing.medium};
    background: ${(theme) => theme.colors.primary};
    z-index: 5;
    @media ${mobile} {
      top: 4rem;
    }
  }
  .rc-tabs-nav-list {
    justify-content: center !important;
    > .rc-tabs-tab {
      width: auto !important;
    }
    > .rc-tabs-tab {
      opacity: 0.5;
      margin: 0 ${(theme) => theme.spacing.medium};
      font-size: 1rem !important;
    }
    > .rc-tabs-ink-bar {
      background-color: ${(theme) => theme.stroke} !important;
    }
    .rc-tabs-tab-active {
      font-weight: unset !important;
      opacity: 1;
    }
  }
  .LE-text {
    font-size: 1.25rem;
  }
  p {
    font-size: 1rem;
  }
`

export function Tabs({ tabs }: TabsProps): ReactElement {
  return (
    <CenteredSection>
      <TabsMaxWidth keepInactiveTabPane>
        {tabs.map((tab) => {
          const key = slugify(
            typeof tab.name === 'string' ? tab.name : tab.label ?? '',
          )
          return (
            <Tab key={key} label={tab.name}>
              {'rows' in tab ? (
                <Rows rows={tab.rows} />
              ) : (
                <Columns spacing="large" columns={tab.columns} />
              )}
            </Tab>
          )
        })}
      </TabsMaxWidth>
    </CenteredSection>
  )
}
